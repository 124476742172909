import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Gallery } from "../../../../content/posts/2021-06-28-hello-new-world/gallery";
import { getAlt } from "../../../../content/posts/2021-06-28-hello-new-world/util.js";
import * as React from 'react';
export default {
  GatsbyImage,
  getImage,
  Gallery,
  getAlt,
  React
};